import React from 'react';
import { Card } from 'antd';
import './../assets/styles.css';
import 'antd/dist/antd.min.css';

export const Error = ({ title, body, args }) => {
    return (
        <div className="central">
            <Card title={title} bordered={true} style={{ width: 600 }}>
                <p>{body}</p>
                {args && (<a href={args}>[戻る]</a>)}
            </Card>
        </div>
    );
}