import React from "react";
import { Textarea } from './Textarea';
import { Radioes } from './Radioes';
import { Checkboxes } from './Checkboxes';
import "./../../assets/styles.css";


export const Elements = ({ enquete, send_type, disabled }) => {
    var options = [];
    var valueArray = "";
    var count = 1;

    // 1:YES/NO 2:radio 3:checkbox 4:textarea
    switch (enquete.type) {
        case 1:
            options = [
                {label: "Yes", value: 1},
                {label: "No", value: 2}
            ]
            return(
                <Radioes
                    name={'Q'+enquete.order}
                    question={enquete.question}
                    hissu_flag={enquete.hissu_flag===1 ? true:false}
                    options={options}
                    send_type={send_type}
                    disabled={disabled}
                    message={send_type===1 ? "必須項目です。選択してください。":"選択してください。"}
                />
            );

        case 2:
            valueArray = Object.values(enquete);
            for (let i=8; i<19; i++) {
                if (valueArray[i]) {
                    options.push({ label: valueArray[i], value: count++ })
                }
            }
            return(
                <Radioes
                    name={'Q'+enquete.order}
                    question={enquete.question}
                    hissu_flag={enquete.hissu_flag===1 ? true:false}
                    options={options}
                    send_type={send_type}
                    disabled={disabled}
                    message={send_type===1 ? "必須項目です。選択してください。":"選択してください。"}
                />
            );

        case 3:
            valueArray = Object.values(enquete);
            for (let j=8; j<19; j++) {
                if (valueArray[j]) {
                    options.push({ label: valueArray[j], value: count++ })
                }
            }
            return(
                <Checkboxes
                    name={'Q'+enquete.order}
                    question={enquete.question}
                    hissu_flag={enquete.hissu_flag===1 ? true:false}
                    options={options}
                    send_type={send_type}
                    disabled={disabled}
                    message={send_type===1 ? "必須項目です。選択してください。":"選択してください。"}
                />
            );

        case 4:
            return (
                <Textarea
                    name={'Q'+enquete.order}
                    question={enquete.question}
                    hissu_flag={enquete.hissu_flag===1 ? true:false}
                    send_type={send_type}
                    disabled={disabled}
                    message={send_type===1 ? "必須項目です。入力してください。":"入力してください。"}
                />
        );

        default:
            return null;

    }

  }