import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { message, Alert } from 'antd';
import { Elements } from './elements/Elements';
import 'antd/dist/antd.min.css';


export const Eachforms = ({ enquete, skey, pkey, answer_flag, domain }) => {
    // 「質問ごとに送信」の場合は
    // Mcubeアンケート管理(必須項目)に影響せず強制的に必須にします。
    enquete.hissu_flag = 1;

    // デフォルト設定
    let defaultValues = {};
    let defaultFlag = false;
    let bname = "Q" + enquete.order + "に回答する";
    if (enquete.answer_value) {
        defaultValues['Q' + enquete.order] = enquete.answer_value;
        defaultFlag = true;
        if (answer_flag) {
            bname = "回答済み";
        } else {
            bname = "Q" + enquete.order + "に回答する（回答済み）";
        }
    } else {
        defaultValues['Q' + enquete.order] = "";
    }

    // 登録
    const [isButtonName, setIsButtonName] = useState(bname);
    const [isError, setIsError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const onSubmit = async (data) => {
        if (isProcessing) return;
        setIsProcessing(true);
        const header = {
            'Content-Type': 'application/json; charset=utf-8',
            "X-Requested-With": "XMLHttpRequest"
        };
        enquete.value = Object.values(data)[0];
        enquete.user_key = pkey;
        enquete.send_type = 2;
        //console.log("enquete", enquete);
        try {
            const endpoint = domain + '/api/v1/seminars/' + skey + '/answers';
            let result = false;
            if (defaultFlag || methods.formState.isSubmitSuccessful) {
                result = await axios.put(endpoint, [enquete], { headers: header });
            } else {
                result = await axios.post(endpoint, [enquete], { headers: header });
            }
            // サーバ側バリデーション
            if (!_.isEmpty(result.data.validates)) {
                const valid = result.data.validates[0];
                methods.setError('Q' + valid.order, {
                    types: "required", message: valid.message
                });
            }
            // 完了
            if (result.data.message === "success") {
                if (answer_flag) {
                    setIsButtonName("回答済み");
                } else {
                    setIsButtonName("Q" + enquete.order + "に回答する（回答済み）");
                }
                const key = 'updatable';
                message.loading({ content: 'Loading...', key, style: { marginTop: '25vh' } });
                setTimeout(() => {
                    message.info({
                        content: 'Q' + enquete.order + ' 回答を受け付けました。',
                        key, duration: 2,
                        className: 'custom-class',
                        style: { marginTop: '25vh' }
                    })
                }, 500);
            }
            //console.log("result", result.data);
        } catch (e) {
            setIsError(true);
        } finally {
            setIsProcessing(false);
        }
    };

    // フォーム
    const methods = useForm({ defaultValues });
    const args = new URL(document.URL);
    if (isError) {
        return (
            <div className="top-line">
                <Alert
                    message="アンケート登録できません"
                    description="しばらく時間をおいてから、もう一度お試しください。
                        問題が継続する場合は、管理者までお問い合わせください。"
                    type="error"
                />
                <hr />
                <a href={args}>[戻る]</a>
            </div>
        );
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="new-line">
                    {(answer_flag &&
                        (defaultFlag || methods.formState.isSubmitSuccessful)) || isProcessing ? (
                        <>
                            <Elements enquete={enquete} send_type={2} disabled={true} />
                            <input type="submit" value={isButtonName} disabled />
                        </>
                    ) : (
                        <>
                            <Elements enquete={enquete} send_type={2} disabled={false} />
                            <input type="submit" value={isButtonName} />
                        </>
                    )}
                </form>
            </FormProvider>
        </>
    );

}