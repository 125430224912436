import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { Error } from './../shares/Error';
import { Loading } from './../shares/Loading';
import { Elements } from './elements/Elements';
import { Complete } from './Complete';


export const Bundleforms = ({ enquetes, skey, pkey, answer_flag, domain, bikou }) => {
    // デフォルト設定
    let defaultValues = {};
    let defaultFlag = false;
    let bname = "回答する";
    _.mapKeys(enquetes, (enquete) => {
        if (enquete.answer_value) {
            defaultValues['Q'+enquete.order] = enquete.answer_value
            defaultFlag = true;
            bname = "回答する（回答済み）";
        } else {
            defaultValues['Q'+enquete.order] = "";
        }
    });

    // 登録
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleteVisible, setIsCompleteVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isButtonName, setIsButtonName] = useState(bname);
    const methods = useForm({defaultValues});
    const onSubmit = async(data) => {
        setIsLoading(true);
        _.mapKeys(enquetes, (enquete, index) => {
            enquete.value = Object.values(data)[index];
            enquete.user_key = pkey;
            enquete.send_type = 1;
        });
        //console.log("enquetes", enquetes);
        try {
            const header = {'Content-Type': 'application/json; charset=utf-8',
                "X-Requested-With": "XMLHttpRequest"}
            var result = false;
            const endpoint = domain + '/api/v1/seminars/' + skey +'/answers';
            if (!defaultFlag) {
                result = await axios.post(endpoint, enquetes, { headers: header });
            } else {
                result = await axios.put(endpoint, enquetes, { headers: header });
            }
            // サーバ側バリデーション
            if (!_.isEmpty(result.data.validates)) {
                _.mapKeys(result.data.validates, (valid) => {
                    methods.setError('Q'+valid.order, {
                        types: "required", message: valid.message
                    });
                });
            }
            // 完了
            if (result.data.message === "success") {
                setIsCompleteVisible(true);
                setIsButtonName("回答する（回答済み）");
            }
            //console.log("result", result.data);
        } catch(e) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }

    // フォーム
    if (isLoading) {
        return <Loading />;
    }
    if (isError) {
        return (
            <Error
                title="アンケート登録できません"
                body="しばらく時間をおいてから、もう一度お試しください。
                    問題が継続する場合は、管理者までお問い合わせください。"
            />
        );
    }
    return (
        <>
            {isCompleteVisible ? (
                <Complete />
            ) : (
                <FormProvider {...methods}>
                    <h2>アンケート</h2>
                    <p>アンケートにご協力ください。個々の質問ごとに選択肢をお選び頂き、
                            [回答する]ボタンを押してください。</p>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="new-line">
                        {answer_flag && defaultFlag ? (
                            <>
                                {enquetes.map((enquete, index) =>
                                    <Elements key={index} enquete={enquete} send_type={1} disabled={true} />
                                )}
                                <input type="submit" value="回答済み" disabled />
                            </>
                        ):(
                            <>
                                {enquetes.map((enquete, index) =>
                                    <Elements key={index} enquete={enquete} send_type={1} disabled={false} />
                                )}
                                <input type="submit" value={isButtonName} />
                            </>
                        )}
                    </form>
                    <p className="bikou" dangerouslySetInnerHTML={{ __html: bikou }} />
                </FormProvider>
            )}
        </>
    );

}