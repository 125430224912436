import React from 'react';
import './../assets/styles.css';

export const Complete = () => {
    return (
        <div className = "App">
            <h2>アンケート</h2>
            <div className = "complete">
                回答を受け付けました。
            </div>
        </div>
    );
}