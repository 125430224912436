import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Bundleforms } from './components/Bundleforms';
import { Eachforms } from './components/Eachforms';
import { Error } from './shares/Error';
import { Loading } from './shares/Loading';
import './assets/styles.css';

export default function App() {
    // URLパラメータ取得
    const args = new URL(document.URL);
    var domain = args.origin;
    // ※localhostの例外処理
    if (_.includes(domain, 'localhost')) {
        domain = "https://enquete-plus-stg.m3dc-vs.com";
    }
    const [pkey] = useState(args.searchParams.get('p'));
    const [skey] = useState(args.searchParams.get('s'));

    return (
        <Router>
            <Switch>
                <Route exact path='/enquete/'>
                    <Home skey={skey} pkey={pkey} domain={domain} />
                </Route>
                <Route>
                    <Error title="NotFound" body="ページが見つかりません。" />
                </Route>
            </Switch>
        </Router>
    );
}

const Home = ({ skey, pkey, domain }) => {
    // データ取得
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let time = new Date().getTime();
                const endpoint = domain + '/api/v1/seminars/'
                    + skey + '/answers?user_key=' + encodeURIComponent(pkey) + '&timestamp=' + time;
                const result = await axios.get(endpoint);
                //console.log(result.data);
                setItems(result.data);
            } catch (e) {
                return (
                    <Error
                        title="アクセスエラーが発生しております"
                        body="しばらく時間をおいてから、再度表示してください。
                            表示できない場合は、管理者までお問い合わせください。"
                    />
                );
            } finally {
                setIsLoading(false);
            }
        }
        fetchData()
    }, [pkey, skey, domain])

    // アンケートフォーム(1一括で回答送信 2:質問ごとに送信)
    const { seminars, enquetes } = items ?? {}
    if (isLoading) {
        return <Loading />;
    }
    if (!seminars) {
        return (
            <Error
                title="セミナー情報が取得できません"
                body="URL不備があります、アンケートにアクセスし直してください。
                    問題が継続する場合は、管理者までお問い合わせください。"
            />
        );
    }
    if (!enquetes || enquetes.length === 0) {
        return (
            <Error
                title="アンケート情報が取得できません"
                body="アンケートが登録されていない可能性がございます。
                    しばらく時間をおいてから、再度表示してください。
                    問題が継続する場合は、管理者までお問い合わせください。"
            />
        );
    }

    const seminar = seminars[0]
    // 非公開の場合は何も表示しない
    if (seminar.public_status === 2) {
        return (<></>);
    }

    return (
        <div className="App">
            {seminar.send_type === 2 && (
                <>
                    <h2>アンケート</h2>
                    <p>アンケートにご協力ください。個々の質問ごとに選択肢をお選び頂き、
                        [回答する]ボタンを押してください。</p>
                </>
            )}
            {seminar.send_type === 1 && (
                <Bundleforms
                    enquetes={enquetes}
                    skey={skey}
                    pkey={pkey}
                    answer_flag={seminar.re_answer_type === 2 ? true : false}
                    domain={domain}
                    bikou={seminar.bikou}
                />
            )}
            {seminar.send_type === 2 && enquetes.map((enquete, index) =>
                <Eachforms
                    key={index}
                    enquete={enquete}
                    skey={skey}
                    pkey={pkey}
                    answer_flag={seminar.re_answer_type === 2 ? true : false}
                    domain={domain}
                />
            )}
        </div>
    );
}